<template>
  <b-button variant="secondary" @click="exportTransactions" class="mt-2">
    <feather type="download"></feather>
  </b-button>
</template>

<script>
import moment from 'moment';
import translations from '@/translations';
import utils from '@/scripts/tools/utils';

export default {
  name: 'ExportTransactionsButton',
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filename: {
      type: String,
      required: true,
    },
    filenameSuffixes: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      translations: translations.finance,
    };
  },
  methods: {
    async exportTransactions() {
      try {
        const { data } = await this.$store.dispatch('Financial/exportTransactions', { ...this.filters });
        const buildFileName = [
          this.filename,
          moment().utc().format('YYYYMMDD_HHmmss'),
          ...this.filenameSuffixes || [],
        ];
        const fileName = `${buildFileName.join('_')}.csv`;
        utils.downloadFile(data, fileName);
      } catch (error) {
        let errorMsg = this.translations.global.errors.export_csv;
        if (error.response && error.response.data && error.response.data.error && error.response.data.error.detail) {
          errorMsg = `${errorMsg}: ${error.response.data.error.detail}`;
        }
        this.$noty.error(errorMsg);
      }
    },
  },
};
</script>
